import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import PureBreadcrumbs from "../../breadcrums";
import { deleteSingleSchedule, fetchSchedule } from '../../../../store/actions/fleetAction';
import { connect } from 'react-redux';
import Table from '../../reacttable/table';
import { FaEdit, FaEye } from 'react-icons/fa';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdDelete } from "react-icons/md";
import { TiTick } from 'react-icons/ti';
import { Bounce, toast, ToastContainer } from 'react-toastify';
function FleetAllSchedule(props) {
    const [isToastVisible, setIsToastVisible] = useState(false);
    useEffect(() => {
        props.fetchScheduleApi()
    }, [])
    const data = props.fetchScheduleRes && props.fetchScheduleRes.data;
    // console.log(props.fetchScheduleRes.data, "schadule");
    const deleteSchedule = (id) => {
        props.deleteScheduleApi(id);
    }

    useEffect(() => {
        if (props.deleteScheduleRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Schedule Has Been Deleted', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.deleteScheduleRes.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.save.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.deleteScheduleRes]);
    const columns = useMemo(
        () => [
            {
                Header: "Fleet Name",
                accessor: "fleet_name",
            },
            {
                Header: "VIN Number",
                accessor: "vin",
            },
            {
                Header: "Station Name",
                accessor: "station_name",
            },
            {
                Header: "Actions",
                accessor: "id",
                Cell: (id) => {
                    return (
                        <div>
                            <span>
                                <a
                                    href={`/dashboard/view_schedule/${id.value}`}
                                    rel="tooltip"
                                    title="View"
                                >
                                    <FaEye />
                                </a>
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                <a
                                    href={`/dashboard/update_schedule/${id.value}`}
                                    rel="tooltip"
                                    title="Edit"
                                >
                                    <FaEdit />
                                </a>
                            </span>
                            {/* &nbsp;&nbsp;  &nbsp;&nbsp;
                            <span style={{ cursor: "pointer" }}>
                                <TiTick
                                onClick={() => activateStation(id.value)}
                                title="Activate"
                                />
                            </span>
                            &nbsp;&nbsp;
                            <span style={{ cursor: "pointer" }}>
                                <AiOutlineCloseCircle
                                    // onClick={() => deactivateStation(id.value)}
                                    title="De-Activate"
                                />
                            </span> */}
                            &nbsp;&nbsp;
                            <span style={{ cursor: "pointer", color: "red", fontSize: "18px" }}>
                                <MdDelete
                                    onClick={() => deleteSchedule(id.value)}
                                    title="Delete"
                                />
                            </span>
                        </div>
                    );
                },
            },
        ],
        []
    )
    return (
        <Container fluid className="pb-4">
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />

            <Row className="mt-4">
                <Col>
                    <Card className="customercard">
                        <div class="table-header">All Schedule Vehical List</div>

                        <div>
                            {props.loading ? <h3 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "250px",
                            }}>Loading data...</h3> :
                                data && data.length > 0 ? (
                                    <div>
                                        {" "}
                                        <Table data={data} columns={columns}></Table>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3 className="loader">No data found</h3>
                                    </div>
                                )}
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.fleet.isLoading,
        fetchScheduleRes: state.fleet.fetch_schedule,
        deleteScheduleRes: state.fleet.delete_single_schedule
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchScheduleApi: (fleet) => dispatch(fetchSchedule(fleet)),
        deleteScheduleApi: (id) => dispatch(deleteSingleSchedule(id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FleetAllSchedule);