import React, { useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import PureBreadcrumbs from "../../breadcrums";
import { connect } from 'react-redux';
import { getAllFleetName } from '../../../../store/actions/fleetAction';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import FleetLiveCharging from './FleetLiveCharging';
import FleetVehicleStatus from './FleetVehicleStatus';

const data = [
    {
        lat: 20.5937,
        lng: 78.9629
    },
    {
        lat: 21.1458,
        lng: 79.0882
    },
    {
        lat: 19.0760,
        lng: 72.8777
    },
    {
        lat: 28.7041,
        lng: 77.1025
    },
    {
        lat: 13.0827,
        lng: 80.2707
    },
    {
        lat: 22.5726,
        lng: 88.3639
    },
    {
        lat: 12.9716,
        lng: 77.5946
    },
    {
        lat: 26.9124,
        lng: 75.7873
    },
    {
        lat: 17.3850,
        lng: 78.4867
    },
    {
        lat: 28.8386,
        lng: 78.7733
    }
];


const containerStyle = {
    width: '56vw',
    height: "800px"
};
const center = {
    lat: 20.5937,
    lng: 78.9629
};

const ClusterIconStyle = [{
    url: "/images/bus1.svg",
    height: 55,
    width: 55
}]
function FleetRealTracking(props) {
    useEffect(() => {
        props.getAllFleetNameApi()
    })

    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <section className="customercard" style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <label style={{ marginTop: "5px" }}>
                    Select Fleet :
                </label>
                <select style={{ width: "170px", border: "1px solid #9f9f9f", borderRadius: "5px" }}>
                    {props.getAllFleetNameRes?.data?.map((item, index) => (
                        <option key={index} value={item.ownername}>{item.ownername}</option>
                    ))}
                </select>
            </section>
            <br />
            <section style={{ display: "flex", gap: "10px", justifyContent: "space-between" }}>
                <Card className="customercard">
                    <Card.Body className="text-center">
                        <Card.Text>
                            <p className="table-header" style={{ textAlign: "left" }}>Live Charging</p>
                        </Card.Text>
                        <div style={{ width: "500px" }}>
                            <FleetLiveCharging />
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={5}
                    >
                        <MarkerClusterer styles={ClusterIconStyle} gridSize={25}
                            minimumClusterSize={1} >
                            {(clusterer) => (
                                data?.map((location) => (
                                    <Marker position={{ lat: location.lat, lng: location.lng }} clusterer={clusterer} />
                                )))}
                        </MarkerClusterer>

                    </GoogleMap>
                </Card>
            </section>
            <br />
            <br />
            <Card>
                <Card.Body className="text-center">
                    <Card.Text>
                        <p className="table-header" style={{ textAlign: "left" }}>Vehicle Live Status</p>
                    </Card.Text>
                    <div>
                        <FleetVehicleStatus />
                    </div>
                </Card.Body>
            </Card>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.fleet.isLoading,
        getAllFleetNameRes: state.fleet.all_fleet_name,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAllFleetNameApi: () => dispatch(getAllFleetName()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FleetRealTracking);
