const initState = {
    isFleetLoading: false,
    allFleetLocation: '',
    vehicle: '',
    heavy: '',
    light_vehicle: '',
    save_vehicle_charging_priority: '',
    save_owner: '',
    vehicle_byoperator: '',
    fleet_live_charging: '',
    all_fleet_name: '',
    fleet_insights: '',
    fleet_livecharging: '',
    fleet_report: '',
    monthly_cosumption_count: '',
    monthly_revenue_count: '',
    fleet_vehicle: '',
    fetchFleetName: '',
    fetchVinNumber: '',
    fetchVinData: '',
    save_fleet_schedule: '',
    save_vehicle: '',
    fetch_schedule: '',
    fetch_event: '',
    fetch_single_schedule: '',
    update_single_schedule: '',
    delete_single_schedule: ''
}
const fleetReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_FLEET':
            return { ...state, isFleetLoading: true }
        case 'LOAD_FLEET_LOCATION_LIST':
            return { ...state, allFleetLocation: action.payload, isFleetLoading: false };
        case 'LOAD_HEAVY_VEHICLE_DATA':
            return { ...state, heavy: action.payload, isFleetLoading: false };
        case 'LOAD_VEHICLE_LOCATION_DATA':
            return { ...state, vehicle: action.payload, isFleetLoading: false };
        case 'LOAD_LIGHT_VEHICLE_DATA':
            return { ...state, light_vehicle: action.payload, isFleetLoading: false };
        case 'SAVE_VEHICLE_CHARGING_PRIORITY_API':
            return { ...state, save_vehicle_charging_priority: action.payload, isFleetLoading: false };
        case 'SAVE_FLEET_OWNER':
            return { ...state, save_owner: action.payload, isFleetLoading: false };
        case 'FETCH_VEHICLE_BY_OPERATOR':
            return { ...state, vehicle_byoperator: action.payload, isFleetLoading: false };
        case 'FETCH_FLEET_LIVE_CHARGING':
            return { ...state, fleet_live_charging: action.payload, isFleetLoading: false };
        case 'FETCH_ALL_FLEET_NAME':
            return { ...state, all_fleet_name: action.payload, isFleetLoading: false };
        case 'FETCH_FLEET_INSIGHTS':
            return { ...state, fleet_insights: action.payload, isFleetLoading: false };
        case 'FLEET_LIVE_CHARGING':
            return { ...state, fleet_livecharging: action.payload, isFleetLoading: false };
        case 'FETCH_FLEET_REPORT':
            return { ...state, fleet_report: action.payload, isFleetLoading: false };
        case 'FETCH_FLEET_CONSUMPTION_GRAPH':
            return { ...state, monthly_cosumption_count: action.payload, isFleetLoading: false };
        case 'FETCH_FLEET_REVENUE_GRAPH':
            return { ...state, monthly_revenue_count: action.payload, isFleetLoading: false };
        case 'FETCH_FLEET_VEHICLE_DATA':
            return { ...state, fleet_vehicle: action.payload, isFleetLoading: false };
        case 'FETCH_FLEET_NAME_API':
            return { ...state, fetchFleetName: action.payload, isFleetLoading: false };
        case 'FETCH_VIN_NUMBER_API':
            return { ...state, fetchVinNumber: action.payload, isFleetLoading: false };
        case 'FETCH_VIN_DATA_API':
            return { ...state, fetchVinData: action.payload, isFleetLoading: false };
        case 'SAVE_FLEET_SCHEDULE_API':
            return { ...state, save_fleet_schedule: action.payload, isFleetLoading: false };
        case 'SAVE_VEHICLE_API':
            return { ...state, save_vehicle: action.payload, isFleetLoading: false };
        case 'FETCH_SCHEDULE_API':
            return { ...state, fetch_schedule: action.payload, isFleetLoading: false };
        case 'FETCH_EVENT_API':
            return { ...state, fetch_event: action.payload, isFleetLoading: false };
        case 'FETCH_SINGLE_SCHEDULE_API':
            return { ...state, fetch_single_schedule: action.payload, isFleetLoading: false };
        case 'UPDATE_SINGLE_SCHEDULE_API':
            return { ...state, update_single_schedule: action.payload, isFleetLoading: false };
        case 'DELETE_SINGLE_SCHEDULE_API':
            return { ...state, delete_single_schedule: action.payload, isFleetLoading: false };

        default:
            return state;
    }
}
export default fleetReducer;

