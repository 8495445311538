import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import PureBreadcrumbs from "../../breadcrums";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import * as Yup from "yup";
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { allEvseAction, fetchaAllDaysListAction, fetchEvseStationAction, fetchTimeMasterListAction } from '../../../../store/actions/evseAction';
import { connect } from 'react-redux';
import { fetchSingleSchedule, updateSchedule } from '../../../../store/actions/fleetAction';

function UpdateSchedule(props) {
    const { t } = useTranslation();
    const { id } = useParams();
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [isMatching, setIsMatching] = useState(false);
    const [selectedDays, setSelectedDays] = useState("");
    const [data, setData] = useState([])
    const [selectedStartTime, setSelectedStartTime] = useState([]);
    const [selectedStartTimeIndex, setSelectedStartTimeIndex] = useState([]);
    const [minDate, setMinDate] = useState("");
    useEffect(() => {
        const today = new Date().toISOString().split("T")[0];
        setMinDate(today);
        props.all()
        props.fetchSingleScheduleApi(id)
        props.getAllDayMaster()
        props.getTimeMaster();
    }, [])

    useEffect(() => {
        setData(props.fetchSingleScheduleRes.data)
    }, [props.fetchSingleScheduleRes])


    useEffect(() => {
        if (props.updateScheduleRes.statuscode === 200) {
            setIsToastVisible(true);
            toast.success('Schedule Has Been Updated', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        } else if (props.updateScheduleRes.statuscode === 405) {
            setIsToastVisible(true);
            toast.error(`❌ ${props.save.error}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    setIsToastVisible(false);
                    window.location.reload();
                },
            });
        }
    }, [props.updateScheduleRes]);

    const vehicleSchema = Yup.object().shape({
        station_name: Yup.string().required("Station name is required"),
        evse_id: Yup.string().required("Evse id is required"),
        timeslot: Yup.array().of(
            Yup.object().shape({
                day: Yup.string().when("calendar_type", {
                    is: "recurring",
                    then: Yup.string().required("Day is required"),
                    otherwise: Yup.string().nullable().notRequired(),
                }),
                start: Yup.string().required("Start Time is required"),
                end: Yup.string().required("End Time is required"),
                date: Yup.string().when("calendar_type", {
                    is: "single",
                    then: Yup.string().required("Date is required"),
                    otherwise: Yup.string().nullable().notRequired(),
                }),
            })
        ).required("Time Slot is required")
    });
    const {
        register,
        control,
        setValue,
        watch,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(vehicleSchema),
        mode: "all"
    });
    const {
        fields: fieldsList1,
        remove: removeList1,
        append: appendList1,
    } = useFieldArray({ control, name: "timeslot" });
    const elementsTime = watch("timeslot");

    const handleDayChange = (e, i) => {
        const value = e.target.value;
        // console.log("day change", value)
        setSelectedDays(value)
        setValue(`timeslot[${i}].day`, value);
        setIsMatching(false)
    }

    const handleChange = (e, i) => {
        const value = e.target.value;
        // console.log(value);
        if (i > 0) {
            setSelectedStartTime(value);
        }
        const selectedIndex = e.target.selectedIndex;
        setSelectedStartTimeIndex((prev) => {
            const newStartTimes = [...prev];
            newStartTimes[i] = selectedIndex;
            return newStartTimes;
        });
        setIsMatching(false)
    };

    const handleStationChange = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const stationId = selectedOption.getAttribute('stationid');
        props.fetch(stationId);
    }
    useEffect(() => {
        if (data) {
            setTimeout(() => {
                const { station_name, evse_id, timeslot } = data;
                setValue("station_name", station_name, { shouldValidate: true });
                setValue("evse_id", evse_id, { shouldValidate: true });
                setValue("timeslot", timeslot, { shouldValidate: true });
                if (data?.calendar_type == "single") {
                    setValue("timeslot[0].start", timeslot[0]?.start, { shouldValidate: true });
                    setValue("timeslot[0].end", timeslot[0]?.end, { shouldValidate: true });
                    setValue("timeslot[0].date", timeslot[0]?.date, { shouldValidate: true });
                }
            }, 1000);
        }
    }, [data], setValue)
    const onSubmit = async (data) => {
        const { timeslot } = data;
        if (timeslot.end <= timeslot.start && data?.calendar_type == "single") {
            setError("timeslot.end", {
                type: "manual",
                message: "End time must be grater than start time",
            });
            return;
        }
        data.id = id;
        props.updateScheduleApi(data)
    };

    return (
        <Container fluid>
            {isToastVisible && <div className="overlay" />}
            <ToastContainer
                position="top-center"
                autoClose={20}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ top: "10%", width: "auto", zIndex: "9999" }}
            />
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}></Col>
                <Col xs={12} md={10}>
                    <br />
                    <Card className="tariffcard">
                        <Card.Text
                            style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}
                        >
                            <b style={{ fontSize: "1.5rem" }}>Update Schedule</b>
                            {isMatching && (
                                <p className="text-danger">{t("Time is already exist in the given days.Please select the days and time wisely.")}</p>
                            )}
                        </Card.Text>
                        <Card.Body>
                            <div className="form-floating">
                                <label>
                                    Fleet Name <span className="mandatory-field">*</span>
                                </label>
                                <input className='form-control' value={data?.fleet_name} disabled />
                            </div>
                            <br />
                            <div className="form-floating">
                                <label>
                                    VIN No. <span className="mandatory-field">*</span>
                                </label>
                                <input className='form-control' value={data?.vin} disabled />
                            </div>
                            <br />
                            <div className="form-floating">
                                <label>
                                    Email <span className="mandatory-field">*</span>
                                </label>
                                <input className='form-control' value={data?.emailid} disabled />
                            </div>
                            <br />
                            <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                                <div className="form-floating">
                                    <label>
                                        Select Station Name <span className="mandatory-field">*</span>
                                    </label>
                                    <select className={`form-select form-control ${errors.station_name ? 'is-invalid' : ''}`} name="stationid" id="floatingSelectGridstationid" {...register("station_name")} onChange={(e) => handleStationChange(e)}>
                                        <option value="">{t("Select Station")}</option>
                                        {props.list && props.list.data?.filter((item) => item.status !== 'Discovery' && item.publish !== false)?.map((station) =>
                                            <option key={station.stationid} value={station.name} stationid={station.stationid}>{station.name}</option>
                                        )}
                                    </select>
                                    <div className="invalid-feedback">{errors.station_name?.message}</div>
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>
                                        Select EVSE ID <span className="mandatory-field">*</span>
                                    </label>
                                    <select defaultValue={data?.evse_id} className={`form-select form-control ${errors.evse_id ? 'is-invalid' : ''}`} name="evse_id" id="floatingSelectGridevse_id" {...register("evse_id")}>
                                        {props.fetchstation && props.fetchstation.evses.map((charger) =>
                                            <option key={charger.evse_id} value={charger.evse_id}>{charger.evse_id}</option>
                                        )}
                                    </select>
                                    <div className="invalid-feedback">{errors.evse_id?.message}</div>
                                </div>
                                <br />
                                <div className="form-floating">
                                    <label>
                                        Calendar Type <span className="mandatory-field">*</span>
                                    </label>
                                    <input className='form-control' value={data?.calendar_type == "recurring" ? "Multiple Days" : "Single Day"} disabled />
                                </div>
                                <br />
                                <div>
                                    {
                                        data?.calendar_type === "recurring" &&
                                        (<>
                                            {fieldsList1.slice(0).map((item, i) => (
                                                <Card.Body key={i}>
                                                    <div className="row g-2 mt-3 ">
                                                        <div className="col-md">
                                                            <div className="form-floating">
                                                                <label>
                                                                    {t("Select days")}
                                                                    <span className="mandatory-field">*</span>
                                                                </label>
                                                                <select
                                                                    className={`form-select form-control ${errors.timeslot?.[i]?.day ? "is-invalid" : ""}`}
                                                                    name={`timeslot[${i}].day`}
                                                                    id="floatingSelectGridday"
                                                                    {...register(`timeslot[${i}].day`)}
                                                                    onChange={(e) => handleDayChange(e, i)}

                                                                >
                                                                    <option value="">{t("Select days")}</option>
                                                                    {props.fetchDayMaster &&
                                                                        props.fetchDayMaster.days?.map((day, idx) => (
                                                                            <option key={idx} value={day.value}>
                                                                                {t(day.key)}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                {errors.timeslot?.[i]?.day?.message}
                                                            </div>
                                                        </div>

                                                        <div className="col-md">
                                                            <div className="form-floating">
                                                                <label>
                                                                    {t("From")}
                                                                    <span className="mandatory-field">*</span>
                                                                </label>
                                                                <select
                                                                    className={`form-select form-control ${errors.timeslot?.[i]?.start ? "is-invalid" : ""}`}
                                                                    name={`timeslot[${i}].start`}
                                                                    id="floatingSelectGridperiod_end"
                                                                    {...register(`timeslot[${i}].start`)}
                                                                    onChange={(e) => handleChange(e, i)}
                                                                >
                                                                    <option value="">{t("Select Start Time")}</option>
                                                                    {props.mastertimelist &&
                                                                        props.mastertimelist.hours &&
                                                                        props.mastertimelist.hours.map((v, k) => (
                                                                            <option key={v.key} value={v.value}>
                                                                                {v.value}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                {errors.timeslot?.[i]?.start?.message}
                                                            </div>
                                                        </div>

                                                        <div className="col-md">
                                                            <div className="form-floating">
                                                                <label>
                                                                    {t("To")}
                                                                    <span className="mandatory-field">*</span>
                                                                </label>
                                                                <select
                                                                    className={`form-select form-control ${errors.timeslot?.[i]?.end ? "is-invalid" : ""}`}
                                                                    name={`timeslot[${i}].end`}
                                                                    id="floatingSelectGridperiod_end"
                                                                    {...register(`timeslot[${i}].end`)}
                                                                >
                                                                    <option value="">{t("Select End Time")}</option>
                                                                    {props.mastertimelist &&
                                                                        props.mastertimelist?.hours &&
                                                                        props.mastertimelist?.hours
                                                                            ?.slice(selectedStartTimeIndex[i])
                                                                            .map((v, k) => (
                                                                                <option key={v.key} value={v.value}>
                                                                                    {v.value}
                                                                                </option>
                                                                            ))}
                                                                </select>
                                                            </div>
                                                            <div className="invalid-feedback">
                                                                {errors.timeslot?.[i]?.end?.message}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-grid gap-2 d-flex justify-content-end p-1">
                                                        <button
                                                            className="btn btn-lg"
                                                            type="button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                removeList1(i);
                                                                setIsMatching(false);
                                                            }}
                                                        >
                                                            <i className="far fa-trash-alt" style={{ color: "red" }}></i>
                                                        </button>
                                                    </div>
                                                </Card.Body>
                                            ))}

                                        </>
                                        )}

                                    {data?.calendar_type === "single"
                                        && (
                                            <>
                                                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                                                    <div style={{ width: "25vw" }}>
                                                        <label>
                                                            Select Date
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <input
                                                            type="date"
                                                            {...register("timeslot[0].date")}
                                                            className={`form-select form-control ${errors.timeslot?.[0]?.date ? "is-invalid" : ""}`}
                                                            name="timeslot[i].date"
                                                            min={minDate}
                                                        />
                                                        <div style={{ color: "red", marginTop: "5px" }}>{errors.timeslot?.[0]?.date?.message}</div>
                                                    </div>
                                                    <div style={{ width: "25vw" }}>
                                                        <label>
                                                            From
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <input
                                                            type="time"
                                                            {...register("timeslot[0].start")} // Corrected name
                                                            className={`form-select form-control ${errors.timeslot?.[0]?.start ? "is-invalid" : ""}`}
                                                            name="timeslot[0].start" // Corrected name
                                                        />
                                                        <div style={{ color: "red", marginTop: "5px" }}>{errors.timeslot?.[0]?.start?.message}</div>
                                                    </div>
                                                    <div style={{ width: "25vw" }}>
                                                        <label>
                                                            To
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <input
                                                            type="time"
                                                            {...register("timeslot[0].end")} // Corrected name
                                                            className={`form-select form-control ${errors.timeslot?.[0]?.end ? "is-invalid" : ""}`}
                                                            name="timeslot[0].end" // Corrected name
                                                        />
                                                        <div style={{ color: "red", marginTop: "5px" }}>{errors.timeslot?.[0]?.end?.message}</div>
                                                    </div>
                                                </div>
                                            </>

                                        )
                                    }
                                </div>
                                <br />
                                <br />
                                <div>
                                    <Card.Footer className="d-grid gap-2 ">
                                        <Row className="d-flex justify-content-between">
                                            <Col lg={3} md={3} sm={4} xs={12} className="mt-2">
                                                {data?.calendar_type == "recurring" && <button
                                                    className="btn btn-dark"
                                                    type="button"
                                                    disabled={isMatching}
                                                    onClick={() =>
                                                        appendList1({
                                                            day: "",
                                                            start_time: "",
                                                            end_time: "",
                                                        })
                                                    }
                                                >
                                                    {" "}
                                                    (+) {t("Add Time Slots")}
                                                </button>}
                                            </Col>
                                            <Col lg={3} md={3} sm={4} xs={12} className="d-md-flex justify-content-end mt-2">
                                                <button
                                                    className="lgn-btn btn"
                                                    type="submit"
                                                    disabled={isMatching}
                                                    onClick={handleSubmit(onSubmit)}
                                                >
                                                    {t("Submit")}
                                                </button>

                                            </Col>

                                        </Row>
                                    </Card.Footer>
                                </div>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.fleet.isLoading,
        fetchDayMaster: state.evse.fetchAllDaysMaster,
        mastertimelist: state.evse.timemaster,
        getAllFleetNameRes: state.fleet.all_fleet_name,
        list: state.evse.allevse,
        fetchstation: state.evse.viewstation,
        saveScheduleRes: state.fleet.save_fleet_schedule,
        fetchSingleScheduleRes: state.fleet.fetch_single_schedule,
        updateScheduleRes: state.fleet.update_single_schedule
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        all: () => dispatch(allEvseAction()),
        fetchSingleScheduleApi: (id) => dispatch(fetchSingleSchedule(id)),
        getAllDayMaster: () => dispatch(fetchaAllDaysListAction()),
        getTimeMaster: () => dispatch(fetchTimeMasterListAction()),
        fetch: (stationid) => dispatch(fetchEvseStationAction(stationid)),
        updateScheduleApi: (data) => dispatch(updateSchedule(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateSchedule);