import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import PureBreadcrumbs from "../../breadcrums";
import { fetchSingleSchedule } from '../../../../store/actions/fleetAction';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
function ViewSchedule(props) {
    const [data, setData] = useState({})
    const { id } = useParams();
    useEffect(() => {
        props.fetchSingleScheduleApi(id)
    }, [])

    useEffect(() => {
        setData(props.fetchSingleScheduleRes.data)
        console.log(props.fetchSingleScheduleRes.data)
    }, [props.fetchSingleScheduleRes])
    return (
        <Container fluid>
            <PureBreadcrumbs />
            <br />
            <Row>
                <Col xs={12} md={1}></Col>
                <Col xs={12} md={10}>
                    <br />
                    <Card className="tariffcard">
                        <Card.Text
                            style={{ fontSize: "14px", padding: "10px", paddingLeft: "15px" }}
                        >
                            <b style={{ fontSize: "1.5rem" }}>View Schedule</b>
                        </Card.Text>
                        <Card.Body>
                            <div className="form-floating">
                                <label>
                                    Fleet Name <span className="mandatory-field">*</span>
                                </label>
                                <input className='form-control' value={data?.fleet_name} disabled />
                            </div>
                            <br />
                            <div className="form-floating">
                                <label>
                                    VIN No. <span className="mandatory-field">*</span>
                                </label>
                                <input className='form-control' value={data?.vin} disabled />
                            </div>
                            <br />
                            <div className="form-floating">
                                <label>
                                    Email <span className="mandatory-field">*</span>
                                </label>
                                <input className='form-control' value={data?.emailid} disabled />
                            </div>
                            <br />
                            <div className="form-floating">
                                <label>
                                    Select Station Name <span className="mandatory-field">*</span>
                                </label>
                                <input className='form-control' value={data?.station_name} disabled />
                            </div>
                            <br />
                            <div className="form-floating">
                                <label>
                                    Select EVSE ID <span className="mandatory-field">*</span>
                                </label>
                                <input className='form-control' value={data?.evse_id} disabled />
                            </div>
                            <br />
                            <div className="form-floating">
                                <label>
                                    Calendar Type <span className="mandatory-field">*</span>
                                </label>
                                <input className='form-control' value={data?.calendar_type == "recurring" ? "Multiple Days" : "Single Day"} disabled />
                            </div>
                            <br />
                            {
                                data?.timeslot?.map((item, i) => (
                                    <div key={i} style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                                        <div style={{ width: "28vw" }}>
                                            <label>
                                                {data?.calendar_type == "recurring" ? "Day" : "Date"}
                                            </label>
                                            <input className='form-control' value={data?.calendar_type == "recurring" ? item?.day : item?.date} disabled />
                                        </div>
                                        <div style={{ width: "28vw" }}>
                                            <label>
                                                Start Time
                                            </label>
                                            <input className='form-control' value={item?.start} disabled />
                                        </div>
                                        <div style={{ width: "28vw" }}>
                                            <label>
                                                End Time
                                            </label>
                                            <input className='form-control' value={item?.end} disabled />
                                        </div>
                                        <br />
                                    </div>
                                ))
                            }
                            <br />
                        </Card.Body>

                        <br />
                        <br />
                    </Card>
                </Col>
                <Col xs={12} md={1}></Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.fleet.isLoading,
        fetchSingleScheduleRes: state.fleet.fetch_single_schedule
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingleScheduleApi: (id) => dispatch(fetchSingleSchedule(id))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewSchedule);