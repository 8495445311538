import { FETCH_FAILED_TRANSACTION_API, COMMAND_URL, CHANGE_AVAILABILITY_API, TRIGGER_RESET_API, TRIGGER_CLEAR_CACHE_API, UNLOCK_CONNECTOR_API, TIGGER_GET_DIAGNOSTICS, TIGGER_MESSAGE, TIGGER_CLEAR_CHARGING_PROFILE, GET_LOCAL_LIST_VERSION, UPDATE_FIRMWARE, GET_COMPOSITE_SCHEDULE, TRIGGER_RESERVE_NOW, TRIGEGR_CANCEL_RESERVATION, GET_CONFIGURATION, OCPP_LOG_API, CHARGER_CONFIG_DATA_API, FETCH_CONFIG_DATA_API, SAVE_CONFIG_API, PREDICTIVE_URL, RFID_LOG_API } from "../../components/config/config";

export const triggerChangeAvailability = (evse_id, connectorId, type) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + CHANGE_AVAILABILITY_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
                "connectorId": parseInt(connectorId),
                "type": type,

            })
        }).then(resp => resp.json())
            .then(data => {
                // console.log("CHANGE_AVAILABILITY", data)
                dispatch({ type: 'TRIGGER_CHANGE_AVAILABILITY', payload: data })
            })
    }
}

export const triggerResetCharger = (evse_id, connectorId, reset_type) => {
    // console.log(evse_id, reset_type)
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + TRIGGER_RESET_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
                "reset_type": reset_type,
            })
        }).then(resp => resp.json())
            .then(data => {
                //  console.log("TRIGGER_RESET_CHARGER", data)
                dispatch({ type: 'TRIGGER_RESET_CHARGER', payload: data })
            })
    }
}

export const triggerClearCache = (evse_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + TRIGGER_CLEAR_CACHE_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
            })
        }).then(resp => resp.json())
            .then(data => {
                // console.log("TRIGGER_CLEAR_CACHE", data)
                dispatch({ type: 'TRIGGER_CLEAR_CACHE', payload: data })
            })
    }
}

export const triggerUnlockConnector = (evse_id, portno) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + UNLOCK_CONNECTOR_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
                "portno": parseInt(portno),
            })
        }).then(resp => resp.json())
            .then(data => {
                // console.log("TRIGGER_UNLOCK_CONNECTOR", data)
                dispatch({ type: 'TRIGGER_UNLOCK_CONNECTOR', payload: data })
            })
    }
}

export const triggerGetDiagnostics = (data, startdate, enddate) => {
    // console.log(startdate,enddate)
    console.log("data", data)
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    const { evse_id, location, retries, retryInterval } = data
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + TIGGER_GET_DIAGNOSTICS, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                'startTime': startdate,
                'stopTime': enddate,
                "evse_id": evse_id,
                "retries": retries,
                "retryInterval": retryInterval,
                "location": location,
            })
        }).then(resp => resp.json())
            .then(data => {
                // console.log("TRIGGER_GET_DIAGNOSTIC", data)
                dispatch({ type: 'TRIGGER_GET_DIAGNOSTIC', payload: data })
            })
    }
}

export const triggerMessage = (evse_id, portno, requestedMessage) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + TIGGER_MESSAGE, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
                "portno": parseInt(portno),
                "requestedMessage": requestedMessage
            })
        }).then(resp => resp.json())
            .then(data => {
                console.log("TRIGGER_MESSAGE", data)
                dispatch({ type: 'TRIGGER_MESSAGE', payload: data })
            })
    }
}

export const triggerClearChargingProfile = (data) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    const { evse_id, portno, id, stackLevel } = data
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + TIGGER_CLEAR_CHARGING_PROFILE, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
                "portno": parseInt(portno),
                "id": parseInt(id),
                "stackLevel": parseInt(stackLevel),
            })
        }).then(resp => resp.json())
            .then(data => {
                // console.log("TRIGGER_CLEAR_CHARGING_PROFILE", data)
                dispatch({ type: 'TRIGGER_CLEAR_CHARGING_PROFILE', payload: data })
            })
    }
}

export const triggerlocalListVersion = (evse_id) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + GET_LOCAL_LIST_VERSION, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
            })
        }).then(resp => resp.json())
            .then(data => {
                // console.log("TRIGGER_LOCAL_LIST_VERSION", data)
                dispatch({ type: 'TRIGGER_LOCAL_LIST_VERSION', payload: data })
            })
    }
}

export const triggerUpdateFirmware = (firmware) => {
    console.log(firmware)
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    const { evse_id, location, retries, retrieveDate, retryInterval } = firmware
    console.log(firmware)
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + UPDATE_FIRMWARE, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
                "location": location,
                "retries": parseInt(retries),
                "retrieveDate": new Date(retrieveDate),
                "retryInterval": parseInt(retryInterval),
            })
        }).then(resp => resp.json())
            .then(data => {
                console.log("TRIGGER_UPDATE_FIRMWARE", data)
                dispatch({ type: 'TRIGGER_UPDATE_FIRMWARE', payload: data })
            })
    }
}

export const triggerGetCompositeSchedule = (c_schedule) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    const { evse_id, charging_rate_unit, portno, duration } = c_schedule
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + GET_COMPOSITE_SCHEDULE, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
                "charging_rate_unit": charging_rate_unit,
                "portno": parseInt(portno),
                "duration": parseInt(duration),
            })
        }).then(resp => resp.json())
            .then(data => {
                // console.log("GET_COMPOSITE_SCHEDULE", data)
                dispatch({ type: 'GET_COMPOSITE_SCHEDULE', payload: data })
            })
    }
}

export const triggerReserveNow = (r_now) => {
    console.log(r_now)
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    const { evse_id, portno, expiry, idtag, reservationid } = r_now
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + TRIGGER_RESERVE_NOW, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
                "portno": parseInt(portno),
                "expiry": new Date(expiry),
                "idtag": idtag,
                "reservationid": parseInt(reservationid),
            })
        }).then(resp => resp.json())
            .then(data => {
                console.log("TRIGGER_RESERVE_NOW", data)
                dispatch({ type: 'TRIGGER_RESERVE_NOW', payload: data })
            })
    }
}

export const triggerCancelReservation = (c_now) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    const { evse_id, reservationid } = c_now
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + TRIGEGR_CANCEL_RESERVATION, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
                "reservationid": parseInt(reservationid),
            })
        }).then(resp => resp.json())
            .then(data => {
                // console.log("TRIGGER_CANCEL_RESERVATION", data)
                dispatch({ type: 'TRIGGER_CANCEL_RESERVATION', payload: data })
            })
    }
}

//triggerGetConfiguration

export const triggerGetConfiguration = (g_config) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    const { evse_id } = g_config
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + GET_CONFIGURATION, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "evse_id": evse_id,
            })
        }).then(resp => resp.json())
            .then(data => {
                // console.log("GET_CONFIGURATION", data)
                dispatch({ type: 'GET_CONFIGURATION', payload: data })
            })
    }
}

export const saveConfig = (data, evse_id) => {
    // console.log(data,evse_id)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'));
    const { configuration_Key } = data
    // console.log(configuration_Key,evse_id)
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + SAVE_CONFIG_API, {
            method: "POST",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                "operator_name": organisation,
                "evse_id": evse_id,
                "role": role,
                "configurationKey": configuration_Key,
            })
        }).then(resp => resp.json())
            .then(data => {
                // console.log("SAVE_NEW_CONFIG_DATA", data)
                dispatch({ type: 'SAVE_NEW_CONFIG_DATA', payload: data })
            })
    }
}

export const fetchConfigData = (evseId) => {
    // console.log("evse id from action", evseId)
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + FETCH_CONFIG_DATA_API + "?evse_id=" + evseId, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json())
            .then(data => {
                console.log("FETCH_NEW_CONFIG_DATA", data)
                dispatch({ type: 'FETCH_NEW_CONFIG_DATA', payload: data })
            })
    }
}


export const fetOCPPLogsData = (evseId, startDate, endDate, page, limit) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    // console.log(organisation)
    const operator = role === "Admin" ? "All" : organisation;
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        // console.log(COMMAND_URL + OCPP_LOG_API + "?evse_id=" + evseId + "&start=" + startDate + "&end=" + endDate + "&operator=" + operator + "&page=" + page + "&limit=" + limit)
        return fetch(COMMAND_URL + OCPP_LOG_API + "?evse_id=" + evseId + "&start=" + startDate + "&end=" + endDate + "&operator=" + operator + "&page=" + page + "&limit=" + limit, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json())
            .then(data => {
                // console.log("GET_CONFIGURATION", data)
                dispatch({ type: 'OCPP_LOG_EVENT', payload: data })
            })
    }
}

export const fetchRFIDLogsAction = (startDate, endDate, page, limit) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    // console.log(organisation)
    const operator = role === "Admin" ? "All" : organisation;
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        // console.log(COMMAND_URL + OCPP_LOG_API + "?evse_id=" + evseId + "&start=" + startDate + "&end=" + endDate + "&operator=" + operator + "&page=" + page + "&limit=" + limit)
        return fetch(COMMAND_URL + RFID_LOG_API + "?start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit + "&operator=" + operator, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json())
            .then(data => {
                console.log("FETCH_ALL_RFID_LOG_DATA", data)
                dispatch({ type: 'FETCH_ALL_RFID_LOG_DATA', payload: data })
            })
    }
}

export const fetchAllOCPPLogsData = (evseId, startDate, endDate, page, limit) => {
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    const operator = role === "Admin" ? "All" : organisation;
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        // console.log(COMMAND_URL + OCPP_LOG_API + "?evse_id=" + evseId + "&start=" + startDate + "&end=" + endDate + "&operator=" + operator + "&page=" + page + "&limit=" + limit)
        return fetch(COMMAND_URL + OCPP_LOG_API + "?evse_id=" + evseId + "&start=" + startDate + "&end=" + endDate + "&operator=" + operator + "&page=" + page + "&limit=" + limit, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json())
            .then(data => {
                // console.log("FETCH_ALL_OCPP_LOG_DATA", data)
                dispatch({ type: 'FETCH_ALL_OCPP_LOG_DATA', payload: data })
            })
    }
}

export const fetchChargerConfigurationData = (g_config) => {
    const { token } = JSON.parse(localStorage.getItem('user'))
    var bearer = 'basic ' + token;
    const { evse_id } = g_config
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + CHARGER_CONFIG_DATA_API + "?evse_id=" + evse_id, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json())
            .then(data => {
                // console.log("GET_CONFIGURATION", data)
                dispatch({ type: 'CHARGER_CONFIG_EVENT', payload: data })
            })
    }
}

export const fetchFailedTransactionAction = (startDate, endDate, org, page, limit) => {
    // console.log(startDate, endDate, org, page, limit)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    // const operator = role === "Admin" ? "All" : organisation;
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + FETCH_FAILED_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json())
            .then(data => {
                // console.log("FETCH_FAILED_TRANSACTION", data)
                dispatch({ type: 'FETCH_FAILED_TRANSACTION', payload: data })
            })
    }
}
export const fetchAllFailedTransactionAction = (startDate, endDate, org, page, limit) => {
    // console.log(startDate, endDate, org, page, limit)
    const { token, role, organisation } = JSON.parse(localStorage.getItem('user'))
    // const operator = role === "Admin" ? "All" : organisation;
    var bearer = 'basic ' + token;
    return dispatch => {
        dispatch({ type: 'LOAD_CHARGER_COMMAND', payload: '' })
        return fetch(COMMAND_URL + FETCH_FAILED_TRANSACTION_API + "?operator=" + org + "&start=" + startDate + "&end=" + endDate + "&page=" + page + "&limit=" + limit, {
            method: "GET",
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(resp => resp.json())
            .then(data => {
                // console.log("FETCH_ALL_FAILED_TRANSACTION", data)
                dispatch({ type: 'FETCH_ALL_FAILED_TRANSACTION', payload: data })
            })
    }
}

